@tailwind base;

@tailwind components;

@tailwind utilities;

/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68D391;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #ffffff;
  }



  .loader:before {
    content: '';
    position: fixed;

    top: 0;
    left: 0;
    z-index: 1000;
}

.loader:after {
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    width: 24px;
    height: 24px;
    border: 5px solid #FFF;
    border-bottom-color: #008ce3;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    
    animation: rotation 1s linear infinite;     
}


  .loader {

    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 